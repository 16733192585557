<template>
  <div class="card record-chart">
    <div v-if="loading" class="d-flex justify-content-center" style="padding: 20px 0">
      <Loading />
    </div>
    <div v-else>
      <div class="card-header position-relative">
        <div class="d-flex align-items-center position-absolute">
          <div class="prev-style">
            <i @click="onChangeDate('prev')" class="fs-18 icon-change-date mdi mdi-chevron-left"></i>
          </div>
          <div class="next-style">
            <i @click="onChangeDate('next')" class="fs-18 icon-change-date mdi mdi-chevron-right"></i>
          </div>
          <div>
            <button @click="onChangeDate('today')" type="button" class="today-style" :disabled="isButtonDisabled">
              <span class="fs-12 text-style">今日</span>
            </button>
          </div>
        </div>
        <div class="d-flex align-items-center w-100 justify-content-center">
          <flatpickr v-model="state.date" :config="state.config" class="form-control flatpickr-input select-date-picker">
          </flatpickr>
        </div>
      </div>
      <!-- end card header -->
      <div class="card-body">
        <apexchart v-if="!loading" class="apex-charts" height="600" dir="ltr" ref="apexChart" :series="state.series"
          :options="chartOptions">
        </apexchart>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import { reactive, computed, onMounted, ref, watch } from 'vue';
import i18n from '../../i18n';
import flatpickr from "flatpickr";
import Vueflatpickr from "vue-flatpickr-component";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import { useStore } from 'vuex';
import { staffService } from "@/services";
import Loading from "@/components/Loading.vue";
import { ceil, debounce, multiply, round } from 'lodash';

flatpickr.localize(Japanese);

export default {
  components: {
    Loading,
    flatpickr: Vueflatpickr
  },
  setup() {
    const apexChart = ref(null)
    const state = reactive({
      date: moment(new Date()).format('YYYY-MM-DD'),
      config: {
        wrap: true,
        altFormat: "Y年m月d日",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      loading: false,
      series: [],
      xaxis: {},
      yaxisStaffMax: 40,
      yaxisPeopleMax: 8
    })

    const store = useStore();
    const facilityId = computed(() => store.getters['settings/getFacilityId']);

    watch(() => [state.date], () => {
      getGraphData()
    })

    watch(facilityId, () => {
      getGraphData();
    })

    const isButtonDisabled = computed(() => {
      return moment().isSame(moment(state.date), 'day');
    })

    const onChangeDate = debounce((type) => {
      if (type === 'prev') {
        state.date = moment(state.date).subtract(1, 'days').format('YYYY-MM-DD');
      } else if (type === 'next') {
        state.date = moment(state.date).add(1, 'days').format('YYYY-MM-DD');
      } else {
        if (!isButtonDisabled.value) {
          state.date = moment().format('YYYY-MM-DD');
        }
      }
    }, 500)

    const roundToNearestTenth = (num) => {
      const multipliedNumber = multiply(num, 1.2);
      const roundedToTenth = multiply(ceil(multipliedNumber / 10), 10);
      return multiply(ceil(roundedToTenth / 4), 4);
    }

    const roundToNearest = (num) => {
      return ceil(multiply(num,  1.2) / 4) * 4
    }

    const chartOptions = computed(() => ({
      mappers: [],
      chart: {
        type: "line",
        stacked: false,
        height: 600,
        redrawOnParentResize: true,
        zoom: {
          type: "x",
          enabled: false,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#5C86E0', '#18BC62', '#E96162'],
      stroke: {
        width: [0, 3, 3],
        //curve: 'smooth'
      },
      dataLabels: {
        enabled: false, // Show labels for note
        enabledOnSeries: [1, 2]
      },
      plotOptions: {
        bar: {
          columnWidth: '50%'
        }
      },
      fill: {
        type: 'solid',
        color: '#B1B9C4',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0,
          opacityTo: 0,
        },
      },
      markers: {
        size: 0,
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
      },
      yaxis: [{
        title: {
          text: '出勤人数',
          style: {
            fontWeight: 500,
          },
        },
        tickAmount: 4,
        min: 0,
        max: state.yaxisStaffMax
      }, {
        opposite: true,
        title: {
          text: '利用者数',
          style: {
            fontWeight: 500,
          },
        },
        tickAmount: 4,
        min: 0,
        max: state.yaxisPeopleMax
      }, {
        show: false,
        opposite: true,
        title: {
          text: '人',
          style: {
            fontWeight: 500,
          },
        },
        tickAmount: 4,
        min: 0,
        max: state.yaxisPeopleMax
      }],
      xaxis: state.xaxis,
      tooltip: {
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          formatter: function (val) {
            return moment(`${state.date} ${val - 1}:00`).format('MM-DD H:mm');
          },
        },
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + "人";
            }
            return y;

          }
        }
      },
      noData: {
        text: i18n.global.t('t-no-data'),
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          color: '#ced4da'
        }
      },
    }))

    const getGraphData = async () => {
      try {
        let body = {
          day: state.date,
          facilityId: facilityId.value
        }

        const response = await staffService.graphDetail(body)
        const { staff, saunaMale, saunaFemale } = response
        const staffData = staff.map(t => ({ x: t.time, y: t.number }))
        const saunaMaleData = saunaMale.map((t) => ({ x: t.time, y: t.number }))
        const saunaFemaleData = saunaFemale.map((t) => ({ x: t.time, y: t.number }))

        const maxStaffPerHour = Math.max(...staffData.map(s => s.y))
        const maxPeoplePerHour = Math.max(...saunaMaleData.map((t) => t.y), ...saunaFemaleData.map(t => t.y))

        state.yaxisStaffMax = maxStaffPerHour === 0 ? 8: roundToNearest(maxStaffPerHour)
        state.yaxisPeopleMax = maxPeoplePerHour === 0 ? 40: roundToNearest(maxPeoplePerHour)

        state.series = [
          {
            data: staffData,
            name: '出勤人数',
            type: 'column'
          },
          {
            data: saunaMaleData,
            name: '男湯',
            type: 'line',
          },
          {
            data: saunaFemaleData,
            name: '女湯',
            type: 'line',
          }
        ]

        state.xaxis = {
          tickAmount: 24,
          tooltip: {
            enabled: false
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val
            }
          }
        }

      } catch (error) {
        console.log(error);
      }
    }

    onMounted(() => {
      getGraphData();
    })

    return { state, onChangeDate, getGraphData, chartOptions, apexChart, isButtonDisabled }
  },
}

</script>

<style lang="scss">
.record-chart {
  .select-date-picker {
    width: 131px;
    border: none;
    color: #6691e7 !important;
    background-color: rgba(102, 145, 231, 0.1) !important;
    border-color: transparent !important;
  }

  .icon-change-date {
    color: #6691e3;
    cursor: pointer;
  }

  .chart-tooltip {
    font-size: 14px;
    color: #878a99;
    width: 350px;
  }

  .chart-tooltip-info {
    width: 75px;
    height: 20px;
    text-align: center;
    font-size: 10px;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chart-tooltip-name {
    // max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
}

.prev-style {
  background-color: rgba(144, 196, 239, 0.15);
  padding: 0.3rem 0.4rem;
  border-radius: 0.25rem 0 0 0.25rem;
}

.next-style {
  background-color: rgba(144, 196, 239, 0.15);
  padding: 0.3rem 0.4rem;
  border-radius: 0 0.25rem 0.25rem 0;
}

.today-style {
  color: #6691e3;
  width: 48px;
  padding: 0.3rem 0.4rem;
  background-color: rgba(144, 196, 239, 0.15);
  border-radius: 0.25rem;
  border: 0;
  margin-left: 9px;
}

.text-style {
  line-height: 2.3;
}

button:disabled,
button[disabled] {
  background-color: rgba(144, 196, 239, 0.15);
  color: rgba(102, 146, 227, 0.7);
  cursor: not-allowed;
}
</style>