<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import Chart from "./Chart.vue";

export default {
    page: {
        title: "Dashboard",
        meta: [{ name: "description", content: '' }],
    },
    components: {
        Layout,
        PageHeader,
        Chart,
    },
    data() {
        return {
            title: this.$t("timesheet.staff-chart"),
            items: [
                {
                    text: this.$t("t-timesheet"),
                    active: true,
                },
                {
                    text: this.$t("timesheet.staff-chart"),
                    active: true
                },
            ],
            date: null
        }
    },
    computed: {
        refreshData() {
            return localStorage.getItem('refresh') ? JSON.parse(localStorage.getItem('refresh')) : false;
        },
        secondRefresh() {
            return localStorage.getItem('second')
        }
    },
    mounted() {
        if (this.refreshData) {
            this.$store.commit('settings/SET_REFRESH_DATA', this.refreshData);
        }
        if (this.secondRefresh) {
            this.$store.commit('settings/SET_SECONDS', this.secondRefresh * 1);
        }
    },
    methods: {
        onChangeDate(date) {
            this.date = date;
        },
    },
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="col-md-12">
            <Chart @onChangeDate="onChangeDate" :dateDefault="date" />
        </div>
    </Layout>
</template>
